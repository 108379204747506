export default {
  URINALYSIS_TESTS: [
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "LEUKOCYTES",
      units: "Leu/μL",
      lower_limit: "0",
      upper_limit: "15.00",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "15",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.000000001",
          flag: "Abnormal",
        },
        {
          lower_limit: "15.000000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "NITRITE",
      units: "mg/dL",
      lower_limit: "0",
      upper_limit: "0.05",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "0.05",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.04999999999",
          flag: "Abnormal",
        },
        {
          lower_limit: "0.0500000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "UROBILINOGEN",
      units: "μmol/L",
      lower_limit: "3.50",
      upper_limit: "17",
      abnormal_flags: [
        {
          lower_limit: "3.5",
          upper_limit: "17",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "3.499999999",
          flag: "Abnormal",
        },
        {
          lower_limit: "17.000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "PROTEIN",
      units: "g/L",
      lower_limit: "0",
      upper_limit: "0.15",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "0.15",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.000000001",
          flag: "Abnormal",
        },
        {
          lower_limit: "0.150000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },

    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "pH",
      units: "",
      lower_limit: "5",
      upper_limit: "9",
      abnormal_flags: [
        {
          lower_limit: "5",
          upper_limit: "9",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "4.9999999999",
          flag: "Abnormal",
        },
        {
          lower_limit: "9.000000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },

    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "BLOOD",
      units: "Ery/μL",
      lower_limit: "0",
      upper_limit: "10",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "10",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.000000001",
          flag: "Abnormal",
        },
        {
          lower_limit: "10.000000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "SPECIFIC GRAVITY",
      units: "",
      lower_limit: "1",
      upper_limit: "1.030",
      abnormal_flags: [
        {
          lower_limit: "1",
          upper_limit: "1.03",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.999999999",
          flag: "Abnormal",
        },
        {
          lower_limit: "1.029999999",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "KETONE (ACETOACETIC ACID)",
      units: "",
      lower_limit: "0",
      upper_limit: "0.5",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "0.5",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.4999999999",
          flag: "Abnormal",
        },
        {
          lower_limit: "0.500000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },

    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "BILIRUBIN",
      units: "μmol/L",
      lower_limit: "0",
      upper_limit: "17",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "17",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.000000001",
          flag: "Abnormal",
        },
        {
          lower_limit: "17.000000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },

    {
      instrument: "AimStrip",
      instrument_code: "AimStrip",
      test_name: "Urinalysis",
      case_id: "",
      accession_number: "",
      result_value: "0",
      result_name: "GLUCOSE",
      units: "mmol/L",
      lower_limit: "0",
      upper_limit: "5",
      abnormal_flags: [
        {
          lower_limit: "0",
          upper_limit: "5",
          flag: "Normal",
        },
        {
          lower_limit: "-10000000000",
          upper_limit: "0.000000001",
          flag: "Abnormal",
        },
        {
          lower_limit: "5.000000001",
          upper_limit: "1000000000",
          flag: "Abnormal",
        },
      ],
      dilution: "0",
      comments: "",
      report_status: false,
      case_type: "URINALYSIS",
    },
  ],
};
