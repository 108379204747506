<template>
  <section id="machine-results">
    <div class="default-modal">
      <el-dialog
        top="5vh"
        v-model="dialogVisible"
        width="70%"
        :before-close="closeDialog"
        class="machine-results-dialog"
      >
        <div class="dialog-header">
          <span class="header-part"> Machine Results </span>
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="closeDialog"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="all-cases-view">
          <div class="popup-date">
            <div
              class="primary-content-view-new"
              v-loading="loading"
              v-if="mergedArray && mergedArray.length"
            >
              <div
                class="listings listings__table listings__all-cases"
                id="all-cases-listings"
              >
                <el-table
                  :data="mergedArray"
                  :row-class-name="tableRowClassName"
                >
                  <el-table-column label="RESULT NAME">
                    <template #default="scope">
                      {{ mergedArray[scope.$index].result_name }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Result VALUE (Current Value) ">
                    <template #default="scope">
                      {{ mergedArray[scope.$index].result_value }}
                    </template>
                  </el-table-column>
                  <el-table-column label="UNITS (Current Units)">
                    <template #default="scope">
                      {{ mergedArray[scope.$index].units }}
                    </template>
                  </el-table-column>
                  <el-table-column label="units (latest units)">
                    <template #default="scope">
                      {{ mergedArray[scope.$index].newUnits }}
                    </template>
                  </el-table-column>
                  <el-table-column label="machine Value (Latest Value)">
                    <template #default="scope">
                      {{ mergedArray[scope.$index].value }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="primary-content-view-new" v-loading="loading" v-else>
              <div
                class="listings listings__table listings__all-cases"
                id="all-cases-listings"
              >
                <el-table :data="generatedMachineResults">
                  <el-table-column label="RESULT NAME">
                    <template #default="scope">
                      {{ generatedMachineResults[scope.$index].result_key }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Result VALUE (latest value)">
                    <template #default="scope">
                      {{ generatedMachineResults[scope.$index].value }}
                    </template>
                  </el-table-column>
                  <el-table-column label="UNITS (latest units)">
                    <template #default="scope">
                      {{ generatedMachineResults[scope.$index].units }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="dialog-footer" v-if="mergedArray && mergedArray.length">
              <el-button class="cancel-button" @click="closeDialog"
                >Cancel</el-button
              >
              <el-button class="blue-save-button" @click="replaceMachineValues">
                Replace
              </el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
export default {
  props: ["machineResults", "urineTestResults"],
  data() {
    return {
      urineResults: null,
      generatedMachineResults: null,
      mergedArray: [],
      dialogVisible: true,
      resultArray: [],
      mismatched: false,
    };
  },
  mounted() {
    this.urineResults = this.urineTestResults;
    this.generatedMachineResults = this.machineResults;
    this.sortResultValues();
    if (this.urineResults && this.urineResults.length) {
      this.resultArray = this.generatedMachineResults.map(
        ({ result_key, value, units }) => ({
          result_key,
          value,
          newUnits: units.trim(),
        })
      );

      this.mergedArray = this.urineResults.map((obj1) => {
        const obj2 = this.resultArray.find(
          (obj2) => obj2.result_key === obj1.result_name
        );
        return { ...obj1, ...obj2 };
      });
    }

    this.mismatched = this.checkDifference(
      this.generatedMachineResults,
      this.urineResults
    );
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.result_value != row.value || row.units != row.newUnits) {
        return "warning-row";
      }
      return "";
    },
    checkDifference(machineResults, testResults) {
      let isDifferent = false;
      const machineValues = machineResults.map(({ value, units }) => ({
        value,
        units: units.trim(),
      }));
      const resultValues = testResults.map(({ result_value, units }) => ({
        value: result_value,
        units: units.trim(),
      }));

      if (machineValues.length !== resultValues.length) {
        isDifferent = true;
      } else {
        // Compare individual objects in each array
        for (let i = 0; i < machineValues.length; i++) {
          const obj1 = machineValues[i];
          const obj2 = resultValues[i];
          // Compare properties of the two objects
          for (let prop in obj1) {
            if (obj1[prop] !== obj2[prop]) {
              isDifferent = true;
              break;
            }
          }

          if (isDifferent) {
            break;
          }
        }
      }

      return isDifferent;
    },
    sortResultValues() {
      this.generatedMachineResults.sort(function (a, b) {
        if (a.result_key < b.result_key) {
          return -1;
        }
        if (a.result_key > b.result_key) {
          return 1;
        }
        return 0;
      });
    },

    closeDialog() {
      this.dialogVisible = false;
      this.$emit("close", false);
    },
    replaceMachineValues() {
      for (let i = 0; i < this.urineResults.length; i++) {
        const match = this.resultArray.find(
          (obj) => obj.result_key === this.urineResults[i].result_name
        );
        if (match) {
          this.urineResults[i].result_value = match.value;
          this.urineResults[i].units = match.units;
        }
      }
      this.closeDialog();
      this.$emit("replaceValues", this.urineResults);
    },
  },
};
</script>

<style lang="scss">
#machine-results {
  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  .default-modal .el-dialog__body {
    border-radius: 5px;
  }
  .listings__table .el-table table {
    border-collapse: separate;
    border-spacing: 0px 5px;
  }
  .listings__table .el-table table tbody tr td .cell {
    line-height: 25px;
  }
  .listings__table .el-table table thead tr th.el-table__cell {
    padding: 0.65em 0 0.5em 0 !important;
    background: #273480 !important;
  }
  .listings__table .el-table table thead tr th .cell {
    color: #fff;
  }
  .el-table__body-wrapper {
    overflow: auto;
  }
  .el-table {
    --el-table-row-hover-bg-color: none;
    .warning-row {
      font-weight: bold;
      background: #f6ba4c;
      &:hover {
        background: #f6ba4c;
      }
    }
  }
}
</style>
