<template>
  <div class="case-creation__step case-creation__test" v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="case-creation__card attachments border-bottom-none">
          <div class="case-creation__heading">
            <div class="title-with-icon">
              <span class="icon">
                <img
                  src="@/assets/images/icons/icon-test-reports.svg"
                  alt="icon"
                  class="img-fluid"
                />
              </span>
              <h3 class="title">Test Results</h3>
            </div>
          </div>
          <div class="case-creation__test-reports">
            <el-tabs v-model="activeCaseName" v-on:tab-click="selectedTabTest">
              <el-tab-pane
                label="Blood Panel"
                v-if="hasBloodPanel"
                name="blood"
              >
                <div class="test-results">
                  <ul class="test-btn" v-if="testResults && testResults.length">
                    <li>
                      <el-button
                        class="test-preview"
                        @click="previewTestResultsData()"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >PREVIEW</el-button
                      >
                    </li>
                    <li>
                      <el-button
                        class="test-reports"
                        @click="generateReport"
                        v-if="caseStatus != 'COMPLETE'"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >GENERATE REPORT</el-button
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="results-tabs"
                  v-if="
                    bloodResults &&
                    Object.keys(bloodResults) &&
                    Object.keys(bloodResults).length
                  "
                >
                  <div class="test-inputs">
                    <el-tabs
                      v-model="activeName"
                      class="demo-tabs"
                      @tab-click="handleClick"
                    >
                      <el-tab-pane
                        v-for="(test, index) of bloodResults"
                        :key="index"
                      >
                        <template #label>
                          <span class="custom-tabs-label">
                            <span>{{ index }}</span>
                          </span>
                        </template>
                        <el-table :data="test">
                          <el-table-column
                            fixed
                            label="RESULT NAME"
                            min-width="180"
                            class="result"
                          >
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].result_name"
                                placeholder="Enter name"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="INSTRUMENT" width="150">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].instrument"
                                placeholder="Enter instrument"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="INSTR.CODE" min-width="150">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].instrument_code"
                                placeholder="Enter instrument code"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="RESULT VALUE" min-width="180">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].result_value"
                                placeholder="Enter value"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="UNITS" min-width="180">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].units"
                                placeholder="Enter units"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="REFERENCE RANGE"
                            min-width="150"
                          >
                            <template #default="scope">
                              {{
                                test[scope.$index].lower_limit +
                                "-" +
                                test[scope.$index].upper_limit
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column label="FLAG" min-width="100">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].flag"
                                placeholder="Enter flag name"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="DILUTION" min-width="120">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].dilution"
                                placeholder="Enter dilution"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="COMMENTS" min-width="180">
                            <template #default="scope">
                              <el-input
                                type="input"
                                v-model="test[scope.$index].comments"
                                placeholder="Enter comment"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <div class="save-btn">
                    <el-button
                      type="success"
                      @click="updateResultsData"
                      v-if="caseStatus != 'COMPLETE'"
                      :disabled="checkUser && !hasAllowedCaseType"
                      >Save</el-button
                    >
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Urinalysis" v-if="hasUrineTest" name="urine">
                <div class="test-results">
                  <ul
                    class="test-btn"
                    v-if="urineTestResults && urineTestResults.length"
                  >
                    <li
                      v-if="
                        machineResults &&
                        machineResults.length &&
                        caseStatus != 'COMPLETE'
                      "
                    >
                      <span class="test-mismatched" v-if="mismatched">
                        MACHINE RESULTS ARE MISMATCHED
                      </span>
                      <span class="test-success" v-else>
                        MACHINE RESULTS ARE AVAILABLE
                      </span>

                      <el-button class="view-results" @click="openResultsPopup">
                        View results
                      </el-button>
                    </li>
                    <li v-else>
                      <span class="test-danger" v-if="caseStatus != 'COMPLETE'">
                        MACHINE RESULTS NOT AVAILABLE</span
                      >
                    </li>
                    <li class="d-flex">
                      <el-button
                        class="test-preview"
                        @click="previewUrineTestResultsData()"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >PREVIEW</el-button
                      >
                      <el-button
                        class="test-reports"
                        @click="generateUrineReport"
                        v-if="caseStatus != 'COMPLETE'"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >GENERATE REPORT</el-button
                      >
                    </li>
                  </ul>
                  <ul v-else class="test-btn">
                    <li
                      v-if="
                        machineResults &&
                        machineResults.length &&
                        caseStatus != 'COMPLETE'
                      "
                    >
                      <span class="test-success">
                        MACHINE RESULTS ARE AVAILABLE
                      </span>
                      <el-button class="view-results" @click="openResultsPopup">
                        View results
                      </el-button>
                    </li>
                    <li v-else>
                      <span class="test-danger">
                        MACHINE RESULTS NOT AVAILABLE</span
                      >
                    </li>
                    <li v-if="checkUser && !hasAllowedCaseType && hasPermission('cases', 'report_upload_new')">
                      <el-button
                        class="test-reports"
                        @click="startResulting"
                        v-if="caseStatus != 'COMPLETE'"
                        :disabled="havingUrinalysisTestInfo"
                        >Start Resulting</el-button
                      >
                    </li>
                    <li v-else>
                      <el-button
                        class="test-reports"
                        @click="startResulting"
                        v-if="caseStatus != 'COMPLETE' && hasPermission('cases', 'report_upload_new')"
                        :disabled="havingUrinalysisTestInfo"
                        >Start Resulting</el-button
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="results-tabs"
                  v-if="
                    urineResults &&
                    Object.keys(urineResults) &&
                    Object.keys(urineResults).length
                  "
                >
                  <div
                    class="test-inputs"
                    :class="{
                      'hide-test-inputs': isHavingOneResults,
                    }"
                  >
                    <el-tabs
                      v-model="activeName"
                      class="demo-tabs"
                      @tab-click="handleClick"
                    >
                      <el-tab-pane
                        v-for="(test, index) of urineResults"
                        :key="index"
                      >
                        <el-table :data="test">
                          <el-table-column
                            fixed
                            label="RESULT NAME"
                            min-width="180"
                            class="result"
                          >
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].result_name"
                                placeholder="Enter name"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>

                          <el-table-column label="INSTRUMENT" width="150">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].instrument"
                                placeholder="Enter instrument"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>

                          <el-table-column label="INSTR.CODE" min-width="150">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].instrument_code"
                                placeholder="Enter instrument code"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="RESULT VALUE" min-width="180">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].result_value"
                                placeholder="Enter value"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="UNITS" min-width="180">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].units"
                                placeholder="Enter units"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column
                            label="REFERENCE RANGE"
                            min-width="150"
                          >
                            <template #default="scope">
                              {{
                                test[scope.$index].lower_limit +
                                "-" +
                                test[scope.$index].upper_limit
                              }}
                            </template>
                          </el-table-column>
                          <el-table-column label="FLAG" min-width="100">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].flag"
                                placeholder="Enter flag name"
                                :disabled="true"
                              ></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="DILUTION" min-width="120">
                            <template #default="scope">
                              <el-input
                                v-model="test[scope.$index].dilution"
                                placeholder="Enter dilution"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>

                          <el-table-column label="COMMENTS" min-width="180">
                            <template #default="scope">
                              <el-input
                                type="input"
                                v-model="test[scope.$index].comments"
                                placeholder="Enter comment"
                                :disabled="caseStatus == 'COMPLETE'"
                              ></el-input>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <div class="save-btn">
                    <el-button
                      type="success"
                      @click="updateUrineResultsData"
                      v-if="caseStatus != 'COMPLETE'"
                      :disabled="checkUser && !hasAllowedCaseType"
                      >Save</el-button
                    >
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="Toxicology"
                class="toxicology-edit-preview"
                v-if="hasToxicTest"
                name="toxic"
              >
                <div
                  class="test-results"
                  v-if="hasPermission('result_files', 'add')"
                >
                  <ul class="test-btn" v-if="showResultsEditing">
                    <li>
                      <el-button
                        class="test-reports"
                        @click="startToxicologyResulting"
                        v-if="caseStatus != 'COMPLETE'"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >Sync Results</el-button
                      >
                    </li>
                    <li>
                      <el-button
                        class="test-preview"
                        @click="previewToxicologyResults()"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >PREVIEW</el-button
                      >
                    </li>

                    <li>
                      <el-button
                        class="test-reports"
                        @click="generateToxiocologyReport"
                        v-if="caseStatus != 'COMPLETE'"
                        :disabled="checkUser && !hasAllowedCaseType"
                        >GENERATE</el-button
                      >
                    </li>
                  </ul>
                  <ul class="test-btn" v-else>
                    <li v-if="checkUser && !hasAllowedCaseType">
                      <el-button
                        class="test-reports"
                        @click="startToxicologyResulting"
                        v-if="
                          caseStatus != 'COMPLETE' &&
                          hasPermission('cases', 'report_upload_new') 
                        "
                        :disabled="havingToxTestInfo"
                        >Start Resulting</el-button
                      >
                    </li>
                    <li v-else>
                      <el-button
                        class="test-reports"
                        @click="startToxicologyResulting"
                        v-if="
                          caseStatus != 'COMPLETE' &&
                          hasPermission('cases', 'report_upload_new')
                        "
                        :disabled="havingToxTestInfo"
                        >Start Resulting</el-button
                      >
                    </li>
                  </ul>
                </div>
                <div
                  class="test-inputs"
                  v-if="hasPermission('result_files', 'add')"
                >
                  <el-form ref="form" :model="toxicologyData">
                    <div class="screening-block">
                      <div
                        class="primary-data-entry"
                        v-if="hasScreeingValidity"
                      >
                        <h3 class="primary-header">Screen Validity</h3>

                        <el-table :data="screenValidity">
                          <el-table-column
                            prop="Full Name"
                            label="Drug"
                            min-width="250"
                          >
                            <template #default="scope">
                              <div class="dark-blue-color">
                                {{
                                  scope.row.report_test_name ||
                                  scope.row["Full name"]
                                }}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="result"
                            label="Result"
                            min-width="180"
                          >
                            <template #default="scope">
                              <div class="pr-20">
                                <el-input
                                  v-model="scope.row.result"
                                  placeholder="Enter Result"
                                  :disabled="caseStatus == 'COMPLETE'"
                                  @input="
                                    checkScreeningValidityTestValues(scope.row)
                                  "
                                ></el-input>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="resultValue"
                            label="Results Value"
                            min-width="180"
                          >
                            <template #default="scope">
                              <div class="pr-20">
                                <el-input
                                  v-model="scope.row.result_value"
                                  placeholder="Enter Result Value"
                                  readonly
                                ></el-input>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="cutOff"
                            label="Cut Off"
                            width="200"
                          >
                            <template #default="scope">
                              <span v-if="scope.row.cutoff">{{
                                scope.row.cutoff +
                                " " +
                                (scope.row.report_test_name != "pH"
                                  ? "ng/mL"
                                  : "")
                              }}</span>
                              <span v-else>--</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>

                      <div class="primary-data-entry" v-if="hasScreeingTests">
                        <h3 class="primary-header">Screen Results</h3>

                        <el-table :data="screenResults" style="width: 100%">
                          <el-table-column
                            prop="Full Name"
                            label="Drug"
                            min-width="250"
                          >
                            <template #default="scope">
                              <div class="dark-blue-color">
                                {{ scope.row.test_name }}
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="result"
                            label="Result"
                            min-width="180"
                          >
                            <template #default="scope">
                              <div class="pr-20">
                                <el-input
                                  v-model="scope.row.result"
                                  placeholder="Enter Result"
                                  :disabled="caseStatus == 'COMPLETE'"
                                  @input="checkScreeningTestValues(scope.row)"
                                ></el-input>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="resultValue"
                            label="Results Value"
                            min-width="180"
                          >
                            <template #default="scope">
                              <div class="pr-20">
                                <el-input
                                  v-model="scope.row.result_value"
                                  placeholder="Enter Result Value"
                                  readonly
                                ></el-input>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="cutOff"
                            label="Cut Off"
                            width="200"
                          >
                          <template #default="scope">
                            <span
                              v-if="
                                (scope.row.high_limit || scope.row.low_limit) &&
                                (scope.row.report_test_name === 'pH' ||
                                  scope.row.report_test_name === 'OXIDANTS')
                              "
                              >{{
                                parseFloat(scope.row.low_limit) +
                                "-" +
                                parseFloat(scope.row.high_limit)
                              }}</span
                            >
                            <span v-else-if="scope.row.cutoff">{{
                              scope.row.cutoff +
                              " " +
                              (scope.row.report_test_name != "URINE CREATININE"
                                ? scope.row.Units
                                : "")
                            }}</span>
                            <span v-else>--</span>
                          </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </div>
                    <div
                      class="conformation-block pb-50"
                      v-if="hasConfirmationTests"
                    >
                      <h2 class="mt-20" v-if="hasConfirmationTests">
                        <span>
                          <img
                            src="@/assets/images/icons/confirmation-tests.svg"
                            alt="icon"
                          />
                        </span>
                        CONFIRMATION TESTS
                      </h2>
                      <div
                        class="primary-data-entry"
                        v-for="(test, index) of categoryCodes"
                        :key="index"
                      >
                        <div class="each-table">
                          <h3 class="primary-header">
                            {{ test.name }}
                          </h3>
                          <el-table :data="test.results" style="width: 100%">
                            <el-table-column
                              prop="report_test_name"
                              label="Test Screen"
                              min-width="250"
                              fixed="left"
                            >
                            </el-table-column>
                            <el-table-column
                              prop="testOutcome"
                              label="Test Outcome"
                              min-width="240"
                            >
                              <template #default="scope">
                                <div class="date-type-checkbox">
                                  <el-radio-group v-model="scope.row.flag">
                                    <el-radio
                                      label="Positive"
                                      value="detected"
                                    ></el-radio>
                                    <el-radio label="Not Detected"></el-radio>
                                  </el-radio-group>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="result"
                              label="Measured Results"
                              min-width="150"
                            >
                              <template #default="scope">
                                <el-input
                                  type="text"
                                  v-model="scope.row.result"
                                  :disabled="caseStatus == 'COMPLETE'"
                                  @input="
                                    checkConfirmationTestValues(scope.row)
                                  "
                                ></el-input>
                                <p v-if="scope.row.invalid_range">
                                  {{ scope.row.invalid_range }}
                                </p>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="determination"
                              label="Determination"
                              width="300"
                            >
                              <template #default="scope">
                                <div class="date-type-checkbox">
                                  <el-radio-group
                                    v-model="scope.row.interpretation"
                                  >
                                    <el-radio label="INCONSISTENT"></el-radio>
                                    <el-radio label="CONSISTENT"></el-radio>
                                  </el-radio-group>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="cutoff"
                              align="center"
                              label="Cut Off"
                              width="100"
                            >
                              <template #default="scope">
                                <span v-if="scope.row.cutoff">{{
                                  scope.row.cutoff +
                                  " " +
                                  (scope.row.Units || scope.row.Unit)
                                }}</span>
                                <span v-else>--</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              align="center"
                              label="Range"
                              width="150"
                            >
                              <template #default="scope">
                                <span>{{
                                  scope.row.LLMI +
                                  "-" +
                                  scope.row.ULMI +
                                  " " +
                                  (scope.row.Units || scope.row.Unit)
                                }}</span>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                  </el-form>
                </div>
              </el-tab-pane>
              <div
                class="save-btn"
                v-if="
                  showResultsEditing && hasPermission('result_files', 'add')
                "
              >
                <el-button
                  type="success"
                  @click="askReason"
                  v-if="caseStatus != 'COMPLETE'"
                  :disabled="checkUser && !hasAllowedCaseType"
                  >Save</el-button
                >
              </div>
            </el-tabs>
          </div>
        </div>
      </el-col>
    </el-row>
    <ResonForChange
      v-if="takeReasonDialog"
      v-on:close="takeReasonDialog = false"
      v-on:reasonSubmit="updateToxicologyResults"
      title="Reason for Update"
    ></ResonForChange>
    <MachineResults
      v-if="dialogVisible"
      v-on:close="dialogVisible = false"
      :urineTestResults="testResultsForUrine"
      :machineResults="machineResults"
      v-on:replaceValues="updateUrineResults"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppHelper from "@/mixins/AppHelper";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import UrinalysisConstants from "@/config/constants/urinalysisConstants";
import ResonForChange from "@/components/misc/ResonForChange";
import MachineResults from "@/components/cases/MachineResults";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  name: "",
  components: {
    ResonForChange,
    MachineResults,
  },
  mixins: [AppHelper, UserPermissionsHelper],
  data() {
    return {
      activeName: "",
      activeCaseName: "",
      loading: false,
      testResults: [],
      testResultsData: {},
      bloodResults: {},
      urineTestResults: [],
      testResultsForUrine: [],
      machineResults: [],
      accession_id: null,
      caseStatus: "",
      urineResults: {},
      urineTests: UrinalysisConstants.URINALYSIS_TESTS,
      generatingUrineReportLoading: false,
      generateUrineReportDialogVisible: false,

      toxicologyData: {
        result: "",
        resultValue: "",
        testOutcome: "",
        measuredResults: "",
        creativeNormalized: "",
        determination: "",
      },
      toxicologyTable: [],
      medicationDialogVisible: false,
      caseId: null,
      reportData: null,
      testCategories: [],
      testReportURL: false,
      showTestReport: false,
      categoryCodes: [],
      screenResults: [],
      screenValidity: [],
      hasScreeingTests: false,
      hasConfirmationTests: false,
      hasScreeingValidity: false,
      prescribedMedications: [],
      showResultsEditing: false,
      takeReasonDialog: false,
      dialogVisible: false,
      mismatched: false,
    };
  },
  async mounted() {
    await this.checkCaseInformation();
  },
  computed: {
    ...mapGetters("cases", [
      "getCaseInformation",
      "getTestResultsUpdateStatus",
      "getBloodReportGenerateStatus",
      "getTestResults",
      "getUrineTestResults",
      "getResultsAddStatus",
      "getUrineTestResultsUpdateStatus",
      "getAllTestResults",
      "getUrineReportGenerateStatus",
      "getToxicInformation",
      "getToxicologyTestResultUpdateStatus",
      "getToxicologyResultsAddStatus",
      "getToxicologyReportGenerateStatus",
      "getUrineMachineResults",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("profile", ["getProfileData"]),
    checkUser() {
      if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.user_type == "LAB_TECHNOLOGIST"
      ) {
        return true;
      }
      return false;
    },
    hasAllowedCaseType() {
      if (
        this.getAuthenticatedUser.allowed_case_types &&
        this.getAuthenticatedUser.allowed_case_types.length
      ) {
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length
        ) {
          const cases = this.getCaseInformation.case_types;
          const casetypes = this.getAuthenticatedUser.allowed_case_types;

          if (cases.every((item) => casetypes.includes(item))) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },

    hasBloodPanel() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.indexOf("CLINICAL_CHEMISTRY") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    hasUrineTest() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.indexOf("URINALYSIS") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },

    havingUrinalysisTestInfo() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.tests_info &&
          this.getCaseInformation.tests_info.length
        ) {
          let havingTests = this.getCaseInformation.tests_info.filter(
            (el) => el.case_type == "URINALYSIS"
          );
          if (havingTests.length != 0) {
            return false;
          }
        } else {
          return true;
        }
      }
      return true;
    },
    havingToxTestInfo() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.tests_info &&
          this.getCaseInformation.tests_info.length
        ) {
          let havingTests = this.getCaseInformation.tests_info.filter(
            (el) => el.case_type_code == "TOXICOLOGY"
          );
          if (havingTests.length != 0) {
            return false;
          }
        } else {
          return true;
        }
      }
      return true;
    },
    havingBloodTestInfo() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.tests_info &&
          this.getCaseInformation.tests_info.length
        ) {
          let havingTests = this.getCaseInformation.tests_info.filter(
            (el) => el.case_type == "CLINICAL_CHEMISTRY"
          );
          if (havingTests.length != 0) {
            return true;
          }
        } else {
          return false;
        }
      }
      return false;
    },
    isHavingOneResults() {
      if (this.urineResults && Object.keys(this.urineResults).length == 1) {
        return true;
      }
      return false;
    },
    hasToxicTest() {
      if (this.getCaseInformation) {
        if (
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.indexOf("TOXICOLOGY") > -1
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    openResultsPopup() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    updateUrineResults(results) {
      this.urineTestResults = results;
      if (this.urineTestResults && this.urineTestResults.length) {
        let group = this.urineTestResults.reduce((r, a) => {
          r[a.test_name] = [...(r[a.test_name] || []), a];
          return r;
        }, {});
        this.urineResults = group;
      }
      this.askConfirmation();
    },
    askConfirmation() {
      // this.$confirm("Are you sure to save test results?", "Warning", {
      //   confirmButtonText: "OK",
      //   cancelButtonText: "Cancel",
      //   type: "warning",
      // }).then(() => {
      //   this.updateUrineResultsData();
      // });
      try {
        this.$confirm("Are you sure to save test results?", "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(() => {
            this.updateUrineResultsData();
          })
          .catch(async () => {
            this.checkCaseInformation();
          });
      } catch (err) {
        console.log(err);
      }
    },
    async askReason() {
      this.takeReasonDialog = true;
    },
    async updateToxicologyResults(reason) {
      try {
        this.loading = true;
        let confirmation_results = this.prepareConfirmationResults();
        let params = {
          sample_id: this.caseId,
        };
        params.update_reason = reason;
        params.result_summary = {
          confirmation_results: confirmation_results,
          screen_results: this.screenResults,
          screen_validity: this.screenValidity,
          screen_result_id: this.reportData.sample_name,
          prescribed_medications: this.prescribedMedications,
        };
        await this.$store.dispatch("cases/updateToxicologyTestResults", params);
        if (this.getToxicologyTestResultUpdateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Test results details updated successfully",
          });
          await this.checkCaseInformation();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in updating Test results details",
          });
        }
        this.loading = false;
        this.takeReasonDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    async previewToxicologyResults() {
      let routerData = this.$router.resolve({
        name: "ToxicologyTestResultsPreview",
        params: {
          case_id: this.caseId,
        },
      });
      window.open(routerData.href, "_blank");
    },
    prepareConfirmationResults() {
      let data = [];
      this.categoryCodes.map((category) => {
        data = [...data, ...category.results];
      });
      return data;
    },
    checkScreeningTestValues(test) {
      if (test.result) {
        let value = parseFloat(test.result);
        if (test.test_name === "pH") {
          if (value < test.low_limit || value > test.high_limit) {
            test.result_value = "Abnormal";
          } else {
            test.result_value = "Normal";
          }
        } else if (test.test_name === "Urine Creatinine") {
          if (value < test.low_limit || value > test.high_limit) {
            test.result_value = "Abnormal";
          } else {
            test.result_value = "Normal";
          }
        } else if (test.test_name === "Oxidants") {
          if (value < test.low_limit || value > test.high_limit) {
            test.result_value = "Abnormal";
          } else {
            test.result_value = "Normal";
          }
        } else if (value > test.cutoff) {
          test.result_value = "Positive";
        } else {
          test.result_value = "Negative";
        }
      } else if (test.result) {
        let value = parseFloat(test.result);
        if (value > test.cutoff) {
          test.result_value = "Positive";
        } else {
          test.result_value = "Negative";
        }
      }
    },

    checkScreeningValidityTestValues(test) {
      if (test.result) {
        let value = parseFloat(test.result);
        if (value < test.low_limit || value > test.high_limit) {
          test.result_value = "Abnormal";
        } else {
          test.result_value = "Normal";
        }
      } else {
        test.result_value = "Normal";
      }
    },
    checkConfirmationTestValues(test) {
      if (test.result) {
        let value = parseFloat(test.result);
        if (value > test.cutoff) {
          test.flag = "Positive";
          if (value > test.ULMI) {
            test.invalid_range = ">" + test.ULMI;
          } else {
            test.invalid_range = "";
          }
        } else {
          test.flag = "Not Detected";
          if (value < test.LLMI) {
            test.invalid_range = "<" + test.LLMI;
          } else {
            test.invalid_range = "";
          }
        }
      } else {
        test.invalid_range = "";
        test.flag = "Not Detected";
      }
      this.updateInterpretationForSelectedTest(test);
    },
    updateInterpretationForSelectedTest(selectedTest) {
      let CONSISTENT, confirmation, comment;
      let flag = selectedTest.flag;
      let grouped = [];
      let testMedications = selectedTest.mapped_medications || [];
      testMedications.push(selectedTest.medication);
      if (selectedTest.test_name) {
        testMedications.push(selectedTest.test_name);
      }
      let found = false;
      if (testMedications && testMedications.length) {
        testMedications = testMedications.map((e) => e.toUpperCase());
        grouped = this.arrayInterSection(
          testMedications,
          this.prescribedMedications
        );
        found = grouped.length > 0;
      }

      if (found) {
        if (flag == "Not Detected") {
          CONSISTENT = "INCONSISTENT";
        } else if (flag === "Not Tested") {
          CONSISTENT = "NOT TESTED";
        } else {
          CONSISTENT = "CONSISTENT";
        }

        comment = "Prescribed";
        confirmation = true;
      } else {
        if (flag == "Positive") {
          CONSISTENT = "INCONSISTENT";
          confirmation = true;
          comment = "Not Prescribed";
        } else if (flag === "Not Tested") {
          CONSISTENT = "NOT TESTED";
        } else {
          CONSISTENT = "CONSISTENT";
        }
      }
      if (selectedTest.not_tested) {
        delete selectedTest["not_tested"];
      }
      selectedTest.interpretation = CONSISTENT;
      selectedTest.confirmation = confirmation;
      selectedTest.comment = comment;
    },
    getCategoryCodeData(category, data) {
      return data
        .filter((result) => {
          if (result.category == category) {
            return result;
          }
        })
        .sort((a, b) => a.report_test_name.localeCompare(b.report_test_name));
    },
    async generateToxiocologyReport() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "cases/generateToxicologyReport",
          this.caseId
        );

        this.loading = false;
        if (this.getToxicologyReportGenerateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Toxicology Report Generated Successfully",
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
          // this.testReportURL = this.getTestReportURL;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error on generating test report",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async fetchToxicInformation() {
      try {
        this.loading = true;

        await this.$store.dispatch("cases/fetchToxicResults", this.caseId);

        if (this.getToxicInformation) {
          this.reportData = this.getToxicInformation;
          if (
            this.reportData &&
            this.reportData.result_summary &&
            ((this.reportData.result_summary.screen_results &&
              this.reportData.result_summary.screen_results.length) ||
              this.reportData.result_summary.screen_validity.length ||
              this.reportData.result_summary.confirmation_results.length)
          ) {
            this.showResultsEditing = true;
          } else {
            this.showResultsEditing = false;
          }
          this.medications =
            this.reportData &&
            this.reportData.result_summary &&
            this.reportData.result_summary.prescribed_medications &&
            this.reportData.result_summary.prescribed_medications.length
              ? this.reportData.result_summary.prescribed_medications.join(
                  ",  "
                )
              : "- No Medications -";
          this.prescribedMedications =
            this.reportData &&
            this.reportData.result_summary &&
            this.reportData.result_summary.prescribed_medications &&
            this.reportData.result_summary.prescribed_medications.length
              ? this.reportData.result_summary.prescribed_medications
              : [];
          // this.screenResults = this.reportData[i].results;
          this.screenResults =
            this.reportData.result_summary.screen_results || [];
          this.screenValidity =
            this.reportData.result_summary.screen_validity || [];

          this.confirmationResults =
            this.reportData.result_summary.confirmation_results || [];

          this.confirmationResults = this.confirmationResults.sort(function (
            a,
            b
          ) {
            return ("" + a.category_code).localeCompare(b.category_code);
          });

          this.hasConfirmationTests = this.confirmationResults.length
            ? true
            : false;
          this.hasScreeingTests = this.screenResults.length ? true : false;

          this.screenResults = this.screenResults.sort(function (a, b) {
            return ("" + a.report_test_name).localeCompare(b.report_test_name);
          });

          this.hasScreeingValidity = this.screenValidity.length ? true : false;

          this.screenValidity = this.screenValidity.sort(function (a, b) {
            return ("" + a.report_test_name).localeCompare(b.report_test_name);
          });

          this.categoryCodes = this.confirmationResults.map(
            (result) => result.category
          );

          // removed duplicates
          this.categoryCodes = [...new Set(this.categoryCodes)];

          this.categoryCodes = this.categoryCodes.map((categoryCode) => {
            return {
              name: categoryCode,
              results: this.getCategoryCodeData(
                categoryCode,
                this.confirmationResults
              ),
            };
          });
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async checkCaseInformation() {
      try {
        this.loading = true;
        this.caseId = this.$route.params.case_id;
        this.accession_id = this.getCaseInformation.accession_id;

        if (!this.caseId) {
          this.loading = false;
          this.$router.push({
            to: "/all-cases",
          });
        } else {
          await Promise.all([
            // this.$store.dispatch("cases/fetchCaseInformation", this.caseId),
            this.$store.dispatch("cases/fetchTestResults", {
              caseId: this.caseId,
            }),
          ]);
          if (!this.getCaseInformation) {
            this.disablePatientInformation = true;
          }

          this.caseStatus = this.getCaseInformation.status;
          if (
            this.getCaseInformation &&
            this.getCaseInformation.case_types.indexOf("CLINICAL_CHEMISTRY") >
              -1
          ) {
            this.activeCaseName = "blood";
            await this.$store.dispatch("cases/fetchAllTestResults", {
              accession_number: this.accession_id,
              get_all: true,
            }),
              (this.testResults = this.getAllTestResults.data);
            if (this.testResults && this.testResults.length) {
              let group = this.testResults.reduce((r, a) => {
                r[a.test_name] = [...(r[a.test_name] || []), a];
                return r;
              }, {});
              this.bloodResults = group;
            }
          } else if (
            this.getCaseInformation &&
            this.getCaseInformation.case_types.indexOf("URINALYSIS") > -1
          ) {
            this.activeCaseName = "urine";
            await this.$store.dispatch("cases/fetchAllUrineTestResults", {
              caseId: this.caseId,
            });
            await this.$store.dispatch("cases/fetchUrineMachineResults", {
              accession_id: this.accession_id,
            });
            this.testResultsForUrine = this.getUrineTestResults.urineTest;
            this.urineTestResults = this.getUrineTestResults.urineTest;
            if (this.urineTestResults && this.urineTestResults.length) {
              let group = this.urineTestResults.reduce((r, a) => {
                r[a.test_name] = [...(r[a.test_name] || []), a];
                return r;
              }, {});
              this.urineResults = group;
            }
            if (
              this.getUrineMachineResults &&
              this.getUrineMachineResults.results
            ) {
              this.machineResults = this.getUrineMachineResults.results;
              this.sortResultValues();
              this.mismatched = this.checkDifference(
                this.machineResults,
                this.urineTestResults
              );
            }
          } else if (
            this.getCaseInformation &&
            this.getCaseInformation.case_types.indexOf("TOXICOLOGY" > -1)
          ) {
            this.activeCaseName = "toxic";
            await this.fetchToxicInformation();
          }
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    sortResultValues() {
      this.machineResults.sort(function (a, b) {
        if (a.result_key < b.result_key) {
          return -1;
        }
        if (a.result_key > b.result_key) {
          return 1;
        }
        return 0;
      });
    },
    checkDifference(machineResults, testResults) {
      let isDifferent = false;
      const machineValues = machineResults.map(({ value, units }) => ({
        value,
        units,
      }));
      const resultValues = testResults.map(({ result_value, units }) => ({
        result_value,
        units,
      }));
      const newArray = resultValues.map((obj) => {
        const newObj = { ...obj }; // make a shallow copy of the original object

        newObj.value = newObj.result_value; // rename the key
        delete newObj.result_value; // remove the old key
        return newObj;
      });

      if (machineValues.length !== newArray.length) {
        isDifferent = true;
      } else {
        // Compare individual objects in each array
        for (let i = 0; i < machineValues.length; i++) {
          const obj1 = machineValues[i];
          const obj2 = newArray[i];
          // Compare properties of the two objects
          for (let prop in obj1) {
            if (obj1[prop] !== obj2[prop]) {
              isDifferent = true;
              break;
            }
          }

          if (isDifferent) {
            break;
          }
        }
      }

      return isDifferent;
    },
    async selectedTabTest() {
      if (this.activeCaseName && this.activeCaseName == "urine") {
        this.showResultsEditing = false;
        await this.$store.dispatch("cases/fetchAllUrineTestResults", {
          caseId: this.caseId,
        });
        await this.$store.dispatch("cases/fetchUrineMachineResults", {
          accession_id: this.accession_id,
        });
        if (
          this.getUrineMachineResults &&
          this.getUrineMachineResults.results
        ) {
          this.machineResults = this.getUrineMachineResults.results;
        }
        this.testResultsForUrine = this.getUrineTestResults.urineTest;
        this.urineTestResults = this.getUrineTestResults.urineTest;
        if (this.urineTestResults && this.urineTestResults.length) {
          let group = this.urineTestResults.reduce((r, a) => {
            r[a.test_name] = [...(r[a.test_name] || []), a];
            return r;
          }, {});
          this.urineResults = group;
        }
      } else if (this.activeCaseName && this.activeCaseName == "blood") {
        this.showResultsEditing = false;

        await this.$store.dispatch("cases/fetchAllTestResults", {
          accession_number: this.accession_id,
          get_all: true,
        });
        this.testResults = this.getAllTestResults.data;
        if (this.testResults && this.testResults.length) {
          let group = this.testResults.reduce((r, a) => {
            r[a.test_name] = [...(r[a.test_name] || []), a];
            return r;
          }, {});
          this.bloodResults = group;
        }
      } else if (this.activeCaseName && this.activeCaseName == "toxic") {
        this.fetchToxicInformation();
      }
    },
    async startToxicologyResulting() {
      try {
        this.loading = true;
        await this.$store.dispatch("cases/createToxicTestResults", this.caseId);
        this.loading = false;
        if (this.getToxicologyResultsAddStatus) {
          this.$notify.success({
            title: "Success",
            message: "Test Results Added Successfully",
          });
          this.activeCaseName = "toxic";
          this.fetchToxicInformation();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error while creating test results",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async startResulting() {
      try {
        for (let i = 0; i < this.urineTests.length; i++) {
          this.urineTests[i].case_id = this.caseId;
          this.urineTests[i].accession_number = this.accession_id;
        }

        this.loading = true;
        let params = {
          urineTestResults: this.urineTests,
          case_id: this.caseId,
        };
        await this.$store.dispatch("cases/createUrineTestResults", params);
        this.loading = false;
        if (this.getResultsAddStatus) {
          successNotification("Test Results Added Successfully");
          this.activeCaseName = "urine";
          this.checkCaseInformation();
        } else {
          errorNotification("Error while creating test results");
        }
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    async previewTestResultsData() {
      let routerData = this.$router.resolve({
        name: "ResultFileBloodHTMLData",
        params: {
          accession_id: this.accession_id,
          case_id: this.$route.params.case_id,
        },
      });
      window.open(routerData.href, "_blank");
    },

    async previewUrineTestResultsData() {
      let routerData = this.$router.resolve({
        name: "ResultFileUrineHTMLData",
        params: {
          accession_id: this.accession_id,
          case_id: this.$route.params.case_id,
        },
      });
      window.open(routerData.href, "_blank");
    },
    async generateUrineReport() {
      try {
        this.loading = true;
        let params = {
          case_type: "URINALYSIS",
          case_id: this.caseId,
        };
        await this.$store.dispatch("cases/generateUrineTestReport", params);

        this.loading = false;

        if (this.getUrineReportGenerateStatus) {
          successNotification("Test Report Generated Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          errorNotification("Error on generating test report");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async generateReport() {
      let params = {
        case_id: this.$route.params.case_id,
        case_type: "CLINICAL_CHEMISTRY",
      };
      try {
        this.loading = true;

        await this.$store.dispatch("cases/generateBloodTestReport", params);

        this.loading = false;

        if (this.getBloodReportGenerateStatus) {
          successNotification("Test Report Generated Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          errorNotification("Error on generating test report");
        }
      } catch (err) {
        console.log(err);
      }
    },

    async updateUrineResultsData() {
      try {
        this.loading = true;

        let params = {
          urine_test_results: this.urineTestResults,
          caseId: this.caseId,
        };
        await this.$store.dispatch("cases/updateUrineTestResults", params);
        this.loading = false;

        if (this.getUrineTestResultsUpdateStatus) {
          successNotification("Test Report Updated Successfully");
          await this.checkCaseInformation();
        } else {
          errorNotification("Error on updating test report");
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async updateResultsData() {
      try {
        this.loading = true;

        let params = {
          blood_test_results: this.testResults,
        };
        await this.$store.dispatch("cases/updateTestResults", params);
        this.loading = false;

        if (this.getTestResultsUpdateStatus) {
          successNotification("Test Report Updated Successfully");
          await this.checkCaseInformation();
        } else {
          errorNotification("Error on updating test report");
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
  watch: {
    getCaseInformation: {
      handler(newCaseInfo) {
        if (newCaseInfo && newCaseInfo.accession_id) {
          this.accession_id = newCaseInfo.accession_id;
        }
      },
      immediate: true, 
      deep: true       
    }
  },
};
</script>
<style lang="scss">
.attachments {
  .test-results {
    .test-btn {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: end;
      margin: 0 15px;
      z-index: 9;

      li {
        .test-preview {
          background-color: #4bb543;
          border: none;
          color: white;
          margin-right: 16px;
          min-height: 30px;
          padding: 5px 16px;
          border-radius: 5px;
        }
        .test-reports {
          background-color: #273480;
          border: none;
          color: white;
          min-height: 30px;
          padding: 5px 16px;
          margin-right: 7px;
          border-radius: 5pxs;
        }
        .view-results {
          background-color: transparent;
          border: none;
          text-decoration: underline;
          color: #0a5da6;
          font-size: 15px;
          min-height: 30px;
          padding: 5px 16px;
          margin-right: 7px;
          border: none;
        }
      }
    }
    .test-success {
      margin: 0 15px 0 10px;
      // color: #0a5da6;
      color: #4bb543;
      font-size: 14px;
      font-weight: 500;
    }
    .test-mismatched {
      margin: 0 15px 0 10px;
      color: #f28b0c;
      font-size: 14px;
      font-weight: 500;
    }
    .test-danger {
      margin: 0 15px 0 10px;
      color: #f24a55;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .results-tabs {
    // padding: 0 20px;
    .el-tabs__active-bar {
      background-color: #0a5da6;
      border-radius: 25px;

      height: 2px;
    }
    .el-tabs__item {
      color: #828282;
      &.is-active {
        color: #0a5da6;
      }
    }

    .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }
    .el-tabs__content {
      padding: 0 20px;
    }
    .el-table th.el-table__cell.is-leaf {
      padding: 3px 0;
      background-color: #273480;
      color: #ffff;
      font-size: 13px;
    }
    .el-table .el-table__cell {
      padding: 5px 0;
      .el-input__inner {
        height: 34px;
      }
    }
    .save-btn {
      display: flex;
      justify-content: flex-end;
      padding: 5px 20px 10px;
      margin-top: 10px;
      .el-button {
        padding: 0px 16px;
        min-height: 29px;
        border: none;
        background: linear-gradient(79.26deg, #f24a55 -13.43%, #0a5da6 137.71%);
        &:hover {
          background: linear-gradient(
            79.26deg,
            #f24a55 -13.43%,
            #0a5da6 137.71%
          );
          color: #ffff;
        }
      }
    }
  }
  .hide-test-inputs {
    .el-tabs__active-bar {
      display: none;
    }
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    .el-tabs__item {
      height: 0;
    }
  }
}
</style>
<style lang="scss">
.toxicology-edit-preview {
  max-height: 400px;
  min-height: 50px;
  overflow: auto;
  padding: 0 10px;

  .test-btn {
    position: absolute;
    right: 0;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px;
    z-index: 9;
  }
  h2 {
    padding: 1rem 5px 0;
    font-size: 20px;
  }
  .primary-header {
    padding: 1.5rem 5px 0.5rem;
    font-size: 16px;
  }
  .el-input__inner,
  .el-radio {
    height: 30px;
  }

  .date-type-checkbox {
    .el-radio__input .el-radio__inner {
      box-shadow: 0 0 0 1px #ced1d6;
      border-color: transparent;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background-color: #0a5da6;
      border-color: #fff;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #0a5da6;
    }
  }
  .listings__table .el-table table {
    border-spacing: 0 4.5px;
  }
  .el-table th.el-table__cell.is-leaf {
    padding: 3px 0;
    background-color: #273480;
    color: white;
    font-size: 13px;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 2.2rem;
    background-color: #fff;

    &.fixed {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
    }
    .btn {
      &.radius-button {
        background-color: #273480;

        &.radius-button-red {
          color: #273480;
          background: #fff;
          border: 1px solid #dedbdb;
          border-radius: 6px;
          &:hover,
          &:active,
          &:focus {
            box-shadow: 0 0 0 1px #dedbdb;
          }
        }
      }
    }
  }
}
.save-btn {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px 10px;
  border-bottom: 1.2px solid #e7e7f7;
  border-radius: 4px;
  .el-button {
    padding: 0px 16px;
    min-height: 29px;
    border: none;
    background: linear-gradient(79.26deg, #f24a55 -13.43%, #0a5da6 137.71%);
    &.is-disabled {
      opacity: 0.7;
      background: linear-gradient(79.26deg, #f24a55 -13.43%, #0a5da6 137.71%);
    }
  }
}
.case-creation__test-reports {
  .el-tabs__content {
    min-height: 50px;
  }
}
</style>
<style lang="scss">
#pane-urine {
  .test-results {
    .test-btn {
      position: absolute;
      right: 0;
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 15px;
      z-index: 9;
    }
  }
}
</style>
